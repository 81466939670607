.messageOptions{
  display: flex;
  border-top: 1px solid #beef9e;
  padding: 0px 0 10px;
}
.messageOptions > div{
  display: flex;
  flex: 1;
  padding-top: 12px;
  height: 35px;
  justify-content: center;
  cursor: pointer;
}
.messageOptions > div  > img{
  cursor: pointer;
  margin-right: 5px;
}

.chatMessageContainerImageBot{
  display: flex;
  justify-content: start;
}

.chatMessageContainerImageUser{
  display: flex;
  justify-content: end;
}

.chatMessageContainerDivBot{
  /* background-color: #041010; */
  color: white;
  padding: 10px 10px;
  border-radius: 10px;
  width: 100%;
  /* max-width: 75%; */
}
.chatMessageContainerDivUser{
  /* background-color: #0c0818; */
  color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 75%;
  text-align: end;
}
.chatMessageContainerLoader{
  /* background-color: #041010; */
  color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  gap: 10px;  
}
.chatMessageContainerLoaderWaitDiv{
  display: flex;
  margin-top: 10px;
  padding-left: 10px;
}
.chatMessageContainerLoaderWaitSpan{
  margin-top: 5px;
  margin-left: 5px;
}
.suggestionsToggle{
  display: flex;
  justify-content: center;
  height: 20px;
  /* margin-bottom: 10px; */
}

.suggestionsToggle img{
  height: 20px;
  cursor: pointer;
}

.chatMessageInputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #7357EB;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  gap: 10px;
  margin: 0px 10px 0px 10px;
  background: #7357EB;
  color: #ffffff;
  position: fixed;
  bottom: 20px;
  width: 72%;  
}

.chatMessageInput {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 25px;
}

.icon {
  cursor: pointer;
}

.chatMessageUserContainer{
  display: flex;
  margin-bottom: 10px; 
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.chatMessageBotContainer{
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-start;
}

.chatMessageContainer{
  padding: 10px;
  height: 500px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

/* src/styles/home.module.css */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.notchIcon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 30px;
}
.networkSignalLight {
  position: relative;
  width: 20px;
  height: 14px;
}
.batteryLight,
.wifiSignalLight {
  position: relative;
  width: 16px;
  height: 14px;
}
.batteryLight {
  width: 25px;
}
.statusIcons {
  position: absolute;
  top: 16px;
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.indicatorIcon {
  position: absolute;
  top: 8px;
  right: 71px;
  width: 6px;
  height: 6px;
}
.timeLight {
  position: absolute;
  top: 12px;
  left: 21px;
  border-radius: var(--br-xl);
  width: 54px;
  height: 21px;
  overflow: hidden;
}
.systemStatus {
  position: relative;
  width: 375px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}
.history {
  flex: 1;
  position: relative;
  line-height: 38px;
}
.component7Child {
  position: relative;
  padding-left: 10px;
  object-fit: cover;
  /* display: none; */
}
.component7 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
  width: 100%;
  align-items: center;
}
.frameChild {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.instanceWrapper {
  border-radius: var(--br-31xl);
  background-color: var(--dark-gray-01);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
}
.newChat {
  position: relative;
  line-height: 22px;
  text-decoration: none;
  color: #fff;
}
.frameParent {
  border-radius: var(--br-13xl);
  border: 1px solid var(--cw-purple);
  box-sizing: border-box;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-7xs) var(--padding-base) var(--padding-7xs)
    var(--padding-7xs);
  gap: var(--gap-5xs);
}
.star242Wrapper {
  border-radius: var(--br-31xl);
  width: 48px;
  height: 36px;
  padding: var(--padding-7xs) var(--padding-xs);
  box-sizing: border-box;
}

.component71,
.component7Inner,
.star242Wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component71 {
  width: 375px;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  font-size: var(--font-size-base);
}
.component7Parent {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.recent {
  position: relative;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
}
.recentWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-xs);
}
.tickCircle11defaultIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.f69cTractor1Icon {
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.browseAndHire {
  flex: 1;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  /* height: 22px; */
}
.div {
  position: relative;
  font-size: var(--font-size-xs);
  line-height: 18px;
  color: var(--dark-content-5);
  text-align: center;
}
.browseAndHireTopFretElancParent,
.whatsTheBest {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.browseAndHireTopFretElancParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.whatsTheBest {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 22px;
  color: var(--dark-content-5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 22px;
  flex-shrink: 0;
}
.frameDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xs);
}
.f69cTractor1Parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame472default {
  border-bottom: 1px solid var(--dark-gray-04);
}
.frame472default,
.tickCircle11defaultParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0;
  gap: var(--gap-3xs);
}
.frame472defaultParent {
  border-radius: var(--br-xs);
  background-color: var(--dark-alpha-11);
  box-shadow: var(--shadow);
  overflow: hidden;
  padding: 0 var(--padding-xs);
  font-size: var(--font-size-base);
  color: var(--dark-content-1);
}
.frame472defaultParent,
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameItem {
  position: relative;
  border-top: 4px solid #060c18;
  box-sizing: border-box;
  width: 327px;
  height: 4px;
}
.frame472defaultGroup {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--dark-alpha-11);
  box-shadow: var(--shadow);
  height: 66px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--dark-content-1);
}
.frame472defaultGroup,
.frameGroup,
.frameParent2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent2 {
  align-self: stretch;
  height: 104px;
  align-items: flex-start;
}
.frameGroup {
  width: 391px;
  overflow: hidden;
  align-items: center;
  padding: var(--padding-3xs) var(--padding-base);
  box-sizing: border-box;
  gap: 18px;
  font-size: var(--font-size-sm);
  color: var(--dark-content-3);
}
.homeFill2Wrapper,
.systemStatusParent {
  align-items: center;
  justify-content: center;
}
.systemStatusParent {
  position: absolute;
  top: 1px;
  left: 0;
  display: flex;
  flex-direction: column;
}
.homeFill2Wrapper {
  flex-direction: row;
  opacity: 0.3;
}
.chat2Wrapper,
.homeFill2Wrapper {
  flex: 1;
  display: flex;
  padding: var(--padding-base) var(--padding-3xs);
}
.chat2Wrapper,
.frameParent5,
.star53Wrapper {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.star53Wrapper {
  flex: 1;
  display: none;
  padding: var(--padding-base) var(--padding-3xs);
  opacity: 0.3;
}
.frameParent5 {
  align-self: stretch;
  display: flex;
  padding: 0 var(--padding-5xl);
}
.homeIndicator {
  position: absolute;
  height: 17.65%;
  width: 35.47%;
  top: 58.82%;
  right: 32.27%;
  bottom: 23.53%;
  left: 32.27%;
  border-radius: var(--br-10xs);
  background-color: var(--dark-content-1);
}
.systemFooter {
  position: relative;
  width: 375px;
  height: 34px;
}
.frameParent4 {
  position: absolute;
  bottom: 0px;
  /* top: 723px; */
  left: 0;
  background-color: var(--dark-alpha-11);
  backdrop-filter: var(--blur);
  border-top: 1px solid var(--dark-alpha-10);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.homeChild {
  position: absolute;
  height: 3.35%;
  width: 8%;
  top: 7.27%;
  right: 52%;
  bottom: 89.38%;
  left: 40%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.component6 {
  position: absolute;
  bottom: 137px;
  left: calc(50% - 160.5px);
  border-radius: 8px;
  background-color: var(--cw-purple);
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px var(--padding-7xs);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-base);
}
.home {
  position: relative;
  background: radial-gradient(50% 50%at 50% 50%, #000, #16305c);
  width: 100%;
  height: 812px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--dark-content-1);
  font-family: var(--font-montserrat);
}

.chatMessageWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-start; */
  gap: var(--gap-base);
}

/* src/styles/home.module.css */
.homeFill2Icon {
  cursor: pointer;
}

.chatMessage {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.chatMessage img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.chatBubble {
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.botMessage {
  background-color: #e0e0e0;
  color: black;
}

.userMessage {
  background-color: #007bff;
  color: white;
}

.loadingMessage {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  color: black;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.loadingMessage img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

@media screen and (min-width:600px)  {
  .home{
    height: 1183px;
  }
  .systemStatusParent{
    align-items: flex-start;
  }
  .frameGroup{
    width: 100%!important;
  }
}
